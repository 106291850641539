import { Alert, Button, Form, Input, Modal, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import { ValidateStatus } from "antd/lib/form/FormItem";
import { AsYouType, isValidPhoneNumber } from "libphonenumber-js";
import { useCallback, useEffect, useRef, useState } from "react";
import { createDonation } from "../api";
import { PaymentProps } from "../typings/payment";
import tapPayLogo from "./assets/tap-pay.svg";

enum ValidationStatus {
  Ok = 0,
  Empty = 1,
  Error = 2,
  Typing = 3,
}

enum CardType {
  Mastercard = "mastercard",
  Visa = "visa",
  Jcb = "jcb",
  Amex = "amex",
  Unknown = "unknown",
}

export default function CreditCardModal({
  isOpen,
  close,
  podcastId,
  donorName,
  donorMessage,
  amount,
}: PaymentProps) {
  const [form] = useForm();
  const [isCardHolderInfoComplete, setIsCardHolderInfoComplete] =
    useState(false);
  const [validation, setValidation] = useState<{
    canGetPrime: boolean;
    cardType: CardType;
    status: {
      number: ValidationStatus;
      expiry: ValidationStatus;
      ccv: ValidationStatus;
    };
  }>();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [formatter] = useState(new AsYouType());
  const [isValidPhone, setIsValidPhone] = useState(true);

  const numRef = useRef(null);
  const expirationDateRef = useRef(null);
  const ccvRef = useRef(null);

  useEffect(() => {
    TPDirect.card.setup({
      fields: {
        number: {
          element: numRef.current,
          placeholder: "**** **** **** ****",
        },
        expirationDate: {
          element: expirationDateRef.current,
          placeholder: "MM / YY",
        },
        ccv: {
          element: ccvRef.current,
          placeholder: "CVV",
        },
      },
      styles: {
        input: {
          color: "rgba(0, 0, 0, 0.85)",
          "font-size": "16px",
        },
      },
    });

    TPDirect.card.onUpdate((update: any) => {
      setValidation(update);
    });
  }, []);

  const handleFinish = useCallback(
    (values: { [key: string]: string }) => {
      setLoading(true);
      const { name, phoneNumber, email } = values;
      TPDirect.card.getPrime((result: any) => {
        if (result.status !== 0) {
          setErrorMessage(result.msg);
          setLoading(false);
          return;
        }

        createDonation(podcastId, {
          prime: result.card.prime,
          donorName: donorName,
          donorMessage: donorMessage,
          amount: amount,
          payerInfo: {
            name,
            email,
            phoneNumber,
          },
        })
          .then((res: any) => {
            window.location.href = res.paymentUrl;
          })
          .catch((err: any) => {
            setErrorMessage(JSON.stringify(err));
          })
          .finally(() => {
            setLoading(false);
          });
      });
    },
    [amount, donorMessage, donorName, podcastId]
  );

  return (
    <Modal
      centered
      title="輸入信用卡資訊"
      open={isOpen}
      onOk={close}
      onCancel={close}
      footer={null}
      forceRender
    >
      <Form
        form={form}
        onValuesChange={async (changedValues, allValues) => {
          const { name, phoneNumber, email } = allValues;
          const isValid = !!phoneNumber && isValidPhoneNumber(phoneNumber);
          setIsCardHolderInfoComplete(
            !!name && !!email && !!phoneNumber && isValid
          );

          if (changedValues.phoneNumber) {
            formatter.reset();
            const formattedPhoneNumber = formatter.input(phoneNumber);
            form.setFieldsValue({ phoneNumber: formattedPhoneNumber });
            setIsValidPhone(isValid);
          }
        }}
        initialValues={{ phoneNumber: "+886 09" }}
        onFinish={handleFinish}
        layout="vertical"
      >
        <Form.Item
          label="信用卡號碼"
          validateStatus={toValidateStatus(validation?.status.number)}
        >
          <div
            className="ant-input"
            style={{ height: 40.15 }}
            id="card-number"
            ref={numRef}
          ></div>
        </Form.Item>
        <div style={{ display: "flex" }}>
          <Form.Item
            style={{ flex: 1, marginRight: 12 }}
            label="到期日"
            validateStatus={toValidateStatus(validation?.status.expiry)}
          >
            <div
              className="ant-input"
              style={{ height: 40.15 }}
              id="card-expiration-date"
              ref={expirationDateRef}
            ></div>
          </Form.Item>
          <Form.Item
            style={{ flex: 1, marginLeft: 12 }}
            label="安全碼 / 卡片後三碼"
            validateStatus={toValidateStatus(validation?.status.ccv)}
          >
            <div
              className="ant-input"
              style={{ height: 40.15 }}
              id="card-ccv"
              ref={ccvRef}
            ></div>
          </Form.Item>
        </div>
        <Form.Item name="name" label="持卡人姓名">
          <Input size="large" />
        </Form.Item>
        <Form.Item
          name="phoneNumber"
          label="持卡人電話"
          help={isValidPhone ? undefined : "請輸入正確的電話號碼"}
          validateStatus={isValidPhone ? undefined : "error"}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          name="email"
          label="持卡人信箱"
          rules={[{ type: "email", message: "請輸入正確的 Email" }]}
        >
          <Input size="large" />
        </Form.Item>
        <div style={{ display: "flex" }}>
          <Button
            style={{ flex: 1, marginRight: 28 }}
            type="primary"
            size="large"
            onClick={form.submit}
            loading={loading}
            disabled={!validation?.canGetPrime || !isCardHolderInfoComplete}
          >
            確認
          </Button>
          <div style={{ flex: 1, marginLeft: 28 }} />
        </div>
        {errorMessage && (
          <Alert type="error" message="付款失敗" description={errorMessage} />
        )}
        <div style={{ display: "flex", marginTop: 32 }}>
          <img src={tapPayLogo} alt="tap-pay-logo" />
          <Typography.Text
            style={{
              fontSize: 12,
              color: "rgba(0, 0, 0, 0.45)",
              marginLeft: 16,
            }}
          >
            本公司採用喬睿科技 TapPay 金流交易系統,消費者刷卡時直接在銀行端
            系統中交易,本公司不會留下您的信用卡資料,以保障你的權益,資料
            傳輸過程採用嚴密的 SSL 2048bit 加密技術保護。
          </Typography.Text>
        </div>
      </Form>
    </Modal>
  );
}

function toValidateStatus(
  status: ValidationStatus | undefined
): ValidateStatus | undefined {
  return status === ValidationStatus.Error ? "error" : undefined;
}
