import { Button, Checkbox, Form, Input, Radio, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import TextArea from "antd/lib/input/TextArea";
import { useCallback, useEffect, useState } from "react";
import { DonationSetting } from "../typings/donate";
import PaymentMethodModal from "./PaymentMethodModal";

export default function DonateSingleForm({
  podcastId,
  setting,
}: {
  podcastId: string;
  setting: DonationSetting;
}) {
  const [form] = useForm();
  const [isPaymentMethodModalOpen, setIsPaymentMethodModalOpen] =
    useState(false);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [donorName, setDonorName] = useState<string>();
  const [donorMessage, setDonorMessage] = useState<string>();

  useEffect(() => {
    setSelectedAmount(setting.amounts[0]);
  }, [setting.amounts]);

  const handleFinish = useCallback((values: any) => {
    setDonorName(values.donorName);
    setDonorMessage(values.donorMessage);
    setIsPaymentMethodModalOpen(true);
  }, []);
  return (
    <>
      <Form
        style={{
          flex: 1,
        }}
        form={form}
        onFinish={handleFinish}
        onValuesChange={(changedValues, allValues) => {
          const { amount, useCustomAmount, customAmount } = changedValues;
          if (amount) {
            form.setFieldsValue({ useCustomAmount: false });
            setSelectedAmount(allValues.amount);
          } else if (useCustomAmount === true) {
            form.setFieldsValue({ amount: undefined });
            setSelectedAmount(allValues.customAmount || 0);
          } else if (useCustomAmount === false) {
            form.setFieldsValue({ amount: setting.amounts[0] });
            setSelectedAmount(setting.amounts[0]);
          } else if (customAmount) {
            form.setFieldsValue({ useCustomAmount: true });
            form.setFieldsValue({ amount: undefined });
            setSelectedAmount(allValues.customAmount || 0);
          }
        }}
        layout="vertical"
        initialValues={{ amount: setting.amounts[0] }}
      >
        <Form.Item name="amount" label="一次性贊助">
          <Radio.Group buttonStyle="solid" size="large">
            {setting.amounts.map((amount, index) => (
              <Radio.Button key={index} value={amount}>
                NT${amount}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Form.Item>
        {setting.isCustomAmountAllowed && (
          <>
            <Form.Item
              name="useCustomAmount"
              valuePropName="checked"
              style={{ marginBottom: 8 }}
            >
              <Checkbox>
                自訂金額{" "}
                <Typography.Text style={{ fontSize: 12 }}>
                  (不得低於NT$50)
                </Typography.Text>
              </Checkbox>
            </Form.Item>
            <Form.Item name="customAmount">
              <Input
                type="number"
                size="large"
                prefix={<Typography.Text>NT$</Typography.Text>}
                placeholder="自訂金額"
              />
            </Form.Item>
          </>
        )}
        <Form.Item
          name="donorName"
          label="名稱"
          rules={[{ max: 50, message: "名稱最多 50 字" }]}
        >
          <Input size="large" placeholder="未填寫則顯示「匿名贊助者」" />
        </Form.Item>
        <Form.Item
          name="donorMessage"
          label="留言"
          rules={[{ max: 150, message: "留言最多 150 字" }]}
        >
          <TextArea
            maxLength={150}
            size="large"
            placeholder="留一段話鼓勵創作者吧！"
            showCount={{
              formatter: ({ count, maxLength }) => `(${count}/${maxLength})`,
            }}
            rows={4}
          />
        </Form.Item>
      </Form>
      <div
        style={{
          backgroundColor: "#FAFAFA",
          paddingLeft: 32,
          paddingRight: 32,
          paddingTop: 16,
          paddingBottom: 8,
          display: "flex",
          flexDirection: "column",
          boxSizing: "border-box",
          width: " calc(100% + 32px)",
          transform: "translateX(-16px)",
        }}
      >
        <Button
          type="primary"
          size="large"
          disabled={selectedAmount < 50}
          onClick={() => form.submit()}
        >{`贊助 NT$${selectedAmount}`}</Button>
        <Typography.Text
          style={{
            fontSize: 12,
            marginTop: 8,
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          Powered by{" "}
          <a href="https://www.soundon.fm/" target="_blank" rel="noreferrer">
            SoundOn
          </a>
        </Typography.Text>
      </div>
      <PaymentMethodModal
        podcastId={podcastId}
        amount={selectedAmount}
        donorName={donorName}
        donorMessage={donorMessage}
        isOpen={isPaymentMethodModalOpen}
        close={() => setIsPaymentMethodModalOpen(false)}
      />
    </>
  );
}
