import { Divider, Space, Typography } from "antd";
import faq from "./faq.json";

export default function SubscriptionDonateFAQ() {
  return (
    <>
      <Typography.Title level={4}>常見問題</Typography.Title>
      <Divider style={{ margin: "12px 0" }} />
      <Space direction="vertical" size={12} style={{ marginBottom: 24 }}>
        {Object.values(faq).map((item) => (
          <FAQCard
            key={item.title}
            title={item.title}
            description={item.description}
          />
        ))}
      </Space>
    </>
  );
}

function FAQCard({
  title,
  description,
}: {
  title: string;
  description: string;
}) {
  return (
    <Space
      direction="vertical"
      style={{
        padding: 16,
        border: "1px solid #d9d9d9",
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <Typography.Text style={{ paddingBottom: 12 }}>{title}</Typography.Text>
      {description.split("\n").map((text, idx) => (
        <Typography.Text key={idx}>{text}</Typography.Text>
      ))}
    </Space>
  );
}
