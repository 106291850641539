import axios from "axios";
import config from "./config";
import {
  CreateDonation,
  DonationSetting,
  SubscriptionProduct,
} from "./typings/donate";
import { Podcast } from "./typings/podcast";
import { getFirebaseAuth } from "./utils/getUserLogin";
import { SubscriptionResp } from "./typings/payment";

const BASE_URL = config.apiEndpoint;

const request = axios.create({ baseURL: BASE_URL });
const requestWithToken = axios.create({ baseURL: BASE_URL });

request.interceptors.request.use(async (requestConfig) => {
  requestConfig.headers = {
    ...requestConfig.headers,
    "api-token": config.apiToken,
  };
  return requestConfig;
});

requestWithToken.interceptors.request.use(async (config) => {
  const auth = getFirebaseAuth();
  if (auth.currentUser) {
    const token = await auth.currentUser.getIdToken();
    config.headers = {
      ...config.headers,
      authorization: "Bearer " + token,
    };
  }
  return config;
});

request.interceptors.response.use(
  (res) => res.data?.data,
  (err) => {
    if (err.isAxiosError) {
      const message = err?.response?.data?.data?.message;
      const _err = message ? new Error(message) : err;
      _err.response = err.response;
      return Promise.reject(_err);
    }
    return Promise.reject(err);
  }
);

requestWithToken.interceptors.response.use(
  (res) => res.data,
  (err) => {
    if (err.isAxiosError) {
      const _err = err?.response?.data;
      return Promise.reject(_err);
    }
    return Promise.reject(err);
  }
);

export const getPodcast = async (podcastId: string): Promise<Podcast> => {
  const response = await request.get(`/client/podcasts/${podcastId}`);
  return response.data;
};

export const getDonationSetting = async (
  podcastId: string
): Promise<DonationSetting> => {
  return request.get(`/client/podcasts/${podcastId}/donation/setting`);
};

export const getProducts = async (
  podcastId: string
): Promise<Array<SubscriptionProduct>> => {
  return request.get(`/client/podcasts/${podcastId}/products`);
};

export const createDonation = async (
  podcastId: string,
  create: CreateDonation
) => {
  return request.post(`/client/podcasts/${podcastId}/donate`, create);
};

export const createSubscription = async (
  productId: string,
  timeUnit: "month" | "year",
  message?: string
): Promise<SubscriptionResp> => {
  return requestWithToken.post(`/client/podcasts/subscriptions`, {
    productId,
    timeUnit,
    message,
  });
};
