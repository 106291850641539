import { Button, Modal, Typography } from "antd";
import { useCallback, useEffect, useState } from "react";
import config from "../config";
import { PaymentProps } from "../typings/payment";
import ApplePayModal from "./ApplePayModal";
import CreditCardModal from "./CreditCardModal";
import GooglePayModal from "./GooglePayModal";
import { ReactComponent as ApplePaySvg } from "./assets/apple-pay.svg";
import { ReactComponent as GooglePaySvg } from "./assets/google-pay.svg";

export default function PaymentMethodModal({
  podcastId,
  donorName,
  donorMessage,
  amount,
  isOpen,
  close,
}: PaymentProps) {
  const [isGooglePayAvailable, setIsGooglePayAvailable] = useState(false);
  const [isApplePayAvailable, setIsApplePayAvailable] = useState(false);
  const [isCreditCardModalOpen, setIsCreditCardModalOpen] = useState(false);
  const [isGooglePayModalOpen, setIsGooglePayModalOpen] = useState(false);
  const [isApplePayModalOpen, setIsApplePayModalOpen] = useState(false);

  const setupGooglePay = useCallback(() => {
    TPDirect.googlePay.setupGooglePay({
      googleMerchantId: config.googlePayMerchantId,
      allowedCardAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
      merchantName: "SoundOn",
      emailRequired: true,
      shippingAddressRequired: true,
      billingAddressRequired: true,
      billingAddressFormat: "MIN",
      phoneNumberRequired: true,
    });

    const paymentRequest = {
      allowedNetworks: ["AMEX", "JCB", "MASTERCARD", "VISA"],
      price: `${amount}`,
      currency: "TWD",
    };
    TPDirect.googlePay.setupPaymentRequest(
      paymentRequest,
      (err: any, result: any) => {
        if (err) {
          console.error(err);
        }
        setIsGooglePayAvailable(!!result.canUseGooglePay);
      }
    );
  }, [amount]);

  const setupApplePay = useCallback(() => {
    TPDirect.paymentRequestApi.setupApplePay({
      merchantIdentifier: config.applePayMerchantId,
      countryCode: "TW",
    });

    const paymentRequest = {
      supportedNetworks: ["AMEX", "JCB", "MASTERCARD", "VISA"],
      supportedMethods: ["apple_pay"],
      total: {
        label: "贊助",
        amount: {
          currency: "TWD",
          value: `${amount}`,
        },
      },
    };

    TPDirect.paymentRequestApi.setupPaymentRequest(
      paymentRequest,
      (result: any) => {
        setIsApplePayAvailable(!!result.browserSupportPaymentRequest);
      }
    );
  }, [amount]);

  useEffect(() => {
    TPDirect.setupSDK(
      config.tapPayAppId,
      config.tapPayAppKey,
      config.tapPayEnv
    );

    setupGooglePay();
    setupApplePay();
  }, [amount, setupGooglePay, setupApplePay]);

  return (
    <>
      <Modal
        centered
        open={isOpen}
        onOk={close}
        closable={false}
        onCancel={close}
        footer={null}
        forceRender
        styles={{ body: { display: "flex", flexDirection: "column" } }}
        style={{ maxWidth: 375 }}
      >
        <Typography.Text
          style={{ fontSize: 20, textAlign: "center", marginBottom: 4 }}
        >
          選擇喜歡的方式贊助
        </Typography.Text>
        <Typography.Text
          style={{ fontSize: 14, textAlign: "center", marginBottom: 32 }}
        >
          本次贊助金額 NT$ {amount}
        </Typography.Text>
        <Button
          type="primary"
          size="large"
          style={{ height: 48, marginBottom: 20 }}
          onClick={() => {
            setIsCreditCardModalOpen(true);
          }}
        >
          信用卡
        </Button>
        <Button
          className="black-btn"
          size="large"
          style={{ height: 48, marginBottom: 20 }}
          onClick={() => {
            setIsGooglePayModalOpen(true);
          }}
          hidden={!isGooglePayAvailable}
        >
          <GooglePaySvg />
        </Button>
        <Button
          className="black-btn"
          size="large"
          style={{ height: 48, marginBottom: 20 }}
          onClick={() => {
            setIsApplePayModalOpen(true);
          }}
          disabled={!isApplePayAvailable}
          hidden={!/(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)}
        >
          {isApplePayAvailable ? (
            <ApplePaySvg />
          ) : (
            "Apple Pay 請使用 Safari 開啟"
          )}
        </Button>
        <Button type="link" size="large" onClick={close}>
          取消
        </Button>
        <Button
          type="link"
          href="https://www.soundon.fm/new-page-1"
          target="_blank"
          className="terms-btn"
        >
          發票須知條款
        </Button>
      </Modal>
      <CreditCardModal
        podcastId={podcastId}
        amount={amount}
        donorName={donorName}
        donorMessage={donorMessage}
        isOpen={isCreditCardModalOpen}
        close={() => {
          setIsCreditCardModalOpen(false);
        }}
      />
      <GooglePayModal
        podcastId={podcastId}
        amount={amount}
        donorName={donorName}
        donorMessage={donorMessage}
        isOpen={isGooglePayModalOpen}
        close={() => {
          setIsGooglePayModalOpen(false);
        }}
      />
      <ApplePayModal
        podcastId={podcastId}
        amount={amount}
        donorName={donorName}
        donorMessage={donorMessage}
        isOpen={isApplePayModalOpen}
        close={() => {
          setIsApplePayModalOpen(false);
        }}
      />
    </>
  );
}
