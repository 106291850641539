import { Spin } from "antd";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";
import { useParams, useLocation } from "react-router-dom";
import { getDonationSetting, getPodcast } from "../api";
import { Podcast } from "../typings/podcast";
import DonateForm from "./DonateForm";
import NotFound from "./NotFound";
import PodcastInfo from "./PodcastInfo";
import { DonationSetting } from "../typings/donate";

export default function Donate() {
  const { podcastId } = useParams<{ podcastId: string }>();
  const [podcast, setPodcast] = useState<Podcast>();
  const [setting, setSetting] = useState<DonationSetting>();
  const [loading, setLoading] = useState(true);
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isMobile = queryParams.get("mobile") === "true";

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const podcast = await getPodcast(podcastId);
        const setting = await getDonationSetting(podcastId);

        setPodcast(podcast);
        setSetting(setting);
      } catch (err) {
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [podcastId]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100vh",
          alignItems: "center",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  if (!podcast || !setting || !setting.isEnabled) {
    return <NotFound message="抱歉，找不到這個 Podcast 節目" />;
  }

  return (
    <div
      style={{ display: "flex", flexDirection: isDesktop ? "row" : "column" }}
    >
      <Helmet title={`贊助並支持你喜愛的「${podcast.title}」吧！`} />
      <div
        style={{
          width: "100%",
          height: isDesktop ? "100vh" : 360,
          background: "linear-gradient(#3E6F8B, #1F3447)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <PodcastInfo
          podcast={podcast}
          coverSize={isDesktop ? 240 : 160}
          titleSize={isDesktop ? 30 : 24}
          subTitleSize={isDesktop ? 20 : 16}
        />
      </div>
      <div
        style={{
          maxWidth: isDesktop ? "480px" : "100%",
          width: isDesktop ? "100%" : "auto",
          display: "flex",
          flexDirection: "column",
          height: isDesktop ? "100vh" : "auto",
          overflowY: isDesktop ? "scroll" : "visible",
        }}
      >
        <DonateForm
          podcastId={podcast.id}
          setting={setting}
          isMobile={isMobile}
        />
      </div>
    </div>
  );
}
