import {
  Alert,
  Button,
  Divider,
  Flex,
  Form,
  Modal,
  Radio,
  RadioChangeEvent,
  Space,
  Typography,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useContext, useState } from "react";
import Showdown from "showdown";
import { createSubscription } from "../api";
import config from "../config";
import { SubscriptionPlan } from "../typings/donate";
import { SubscriptionResp } from "../typings/payment";
import { AuthContext } from "./App";

enum ModalType {
  INITIAL = "initial",
  SELECT_PLAN = "select plan",
  DONATOR_INFO_FORM = "donator info form",
  ERROR = "error",
}

export enum TimeUnitOptions {
  MONTH = "month",
  YEAR = "year",
}

export default function SubScriptionDonateForm({
  subscriptionPlans,
}: {
  subscriptionPlans: SubscriptionPlan[];
}) {
  return (
    <>
      <Space direction="vertical">
        {subscriptionPlans.map((plan) => (
          <PlanCard key={plan.planId} plan={plan} />
        ))}
      </Space>
    </>
  );
}

function PlanCard({ plan }: { plan: SubscriptionPlan }) {
  const defaultUSerSelected = {
    price: plan.monthPrice,
    timeUnit: TimeUnitOptions.MONTH,
    description: "",
  };
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState<ModalType>(ModalType.INITIAL);
  const [errorMsg, setErrorMsg] = useState("");

  const [userSelected, setUserSelected] = useState(defaultUSerSelected);

  const { userLoginStatus } = useContext(AuthContext);

  const handleModalConfirm = () => {
    if (modalType === ModalType.INITIAL) {
      setModalType(ModalType.SELECT_PLAN);
    } else if (modalType === ModalType.SELECT_PLAN) {
      setModalType(ModalType.DONATOR_INFO_FORM);
    } else if (modalType === ModalType.DONATOR_INFO_FORM) {
      setModalOpen(false);
      setModalType(ModalType.INITIAL);
      setUserSelected(defaultUSerSelected);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setModalType(ModalType.INITIAL);
    setUserSelected(defaultUSerSelected);
  };

  const handlePayment = async () => {
    if (!plan.planId) {
      return;
    }
    try {
      const res = await createSubscription(
        plan.planId,
        userSelected.timeUnit,
        userSelected.description
      );

      if (res.result === "success") {
        window.location.href = res.data.url;
      }
    } catch (err) {
      const error = err as SubscriptionResp;
      if (error.result === "error") {
        if (error.status === 400) {
          setErrorMsg("此方案不存在或已訂閱此節目");
        } else {
          setErrorMsg("訂閱失敗，請稍後再試");
        }
      }

      setModalType(ModalType.ERROR);
    }
  };

  return (
    <>
      <PlanItem plan={plan} handleCardClick={() => setModalOpen(true)} />
      <Modal
        title={
          <>
            {modalType === ModalType.INITIAL && <>{plan.title}</>}
            {modalType === ModalType.SELECT_PLAN && <>選擇訂閱的期限</>}
          </>
        }
        open={modalOpen}
        onCancel={handleModalClose}
        onOk={handleModalConfirm}
        style={{ display: "flex", flexDirection: "column" }}
        styles={{
          footer: {
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          },
        }}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            {modalType === ModalType.ERROR ? (
              <CancelBtn />
            ) : (
              <>
                {modalType === ModalType.DONATOR_INFO_FORM ? (
                  <Button type="primary" onClick={handlePayment}>
                    下一步
                  </Button>
                ) : (
                  <>
                    <OkBtn />
                    {modalType === ModalType.INITIAL && !userLoginStatus && (
                      <Typography.Text style={{ marginTop: 12 }}>
                        登入使用訂閱贊助，前往
                        <Typography.Link
                          href={config.playerUrl}
                          target="_blank"
                          style={{ textDecoration: "underline" }}
                        >
                          登入
                        </Typography.Link>
                      </Typography.Text>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
        okText={modalType === ModalType.INITIAL ? "贊助此方案" : "下一步"}
        okButtonProps={{
          style: {
            width: "fit-content",
          },
          disabled: !userLoginStatus,
        }}
        cancelText="取消"
      >
        {modalType === ModalType.INITIAL && <PlanDescription plan={plan} />}
        {modalType === ModalType.SELECT_PLAN && (
          <PriceRadio
            plan={plan}
            handleRadioChange={(e) => {
              const timeUnit = e.target.value;
              setUserSelected({
                ...userSelected,
                price:
                  timeUnit === TimeUnitOptions.YEAR
                    ? plan.yearPrice
                    : plan.monthPrice,
                timeUnit,
              });
            }}
          />
        )}
        {modalType === ModalType.DONATOR_INFO_FORM && (
          <DonatorMsgForm
            handleDonatorFormValues={(description) => {
              setUserSelected({ ...userSelected, description });
            }}
          />
        )}
        {modalType === ModalType.ERROR && (
          <Alert
            message="錯誤"
            description={errorMsg}
            type="error"
            showIcon
            style={{ marginTop: 24 }}
          />
        )}
      </Modal>
    </>
  );
}

function PlanDescription({ plan }: { plan: SubscriptionPlan }) {
  const converter = new Showdown.Converter();
  return (
    <Flex vertical>
      <Typography.Text>
        NT {plan.monthPrice.toLocaleString()} /月
      </Typography.Text>
      <Typography.Text>
        NT {plan.yearPrice.toLocaleString()} /年
      </Typography.Text>
      <Divider style={{ margin: "12px 0" }} />
      <div
        dangerouslySetInnerHTML={{
          __html: converter.makeHtml(plan.description),
        }}
        style={{ maxHeight: 300, overflowY: "scroll" }}
      />
    </Flex>
  );
}

function PriceRadio({
  plan,
  handleRadioChange,
}: {
  plan: SubscriptionPlan;
  handleRadioChange: (event: RadioChangeEvent) => void;
}) {
  return (
    <>
      <Divider style={{ margin: "12px 0" }} />
      <Flex justify="center" gap={24} style={{ margin: "36px 0" }}>
        <Radio.Group
          optionType="button"
          onChange={(e) => handleRadioChange(e)}
          defaultValue={TimeUnitOptions.MONTH}
        >
          <Radio.Button
            className="subscription-plan-price-radio"
            value={TimeUnitOptions.MONTH}
            style={{ marginRight: 12, borderRadius: 6 }}
          >
            NT {plan.monthPrice.toLocaleString()} /月
          </Radio.Button>
          <Radio.Button
            className="subscription-plan-price-radio"
            value={TimeUnitOptions.YEAR}
            style={{ marginLeft: 12, borderWidth: 1, borderRadius: 6 }}
          >
            NT {plan.yearPrice.toLocaleString()} /年
          </Radio.Button>
        </Radio.Group>
      </Flex>
    </>
  );
}

function DonatorMsgForm({
  handleDonatorFormValues,
}: {
  handleDonatorFormValues: (description: string) => void;
}) {
  const [form] = Form.useForm<{ description: string }>();
  const [errorStatus, setErrorStatus] = useState(false);

  return (
    <Form
      form={form}
      layout="vertical"
      style={{ marginTop: 12, marginBottom: 48 }}
      onValuesChange={(changedValues) => {
        const { description } = changedValues;
        handleDonatorFormValues(description);
      }}
    >
      <Form.Item
        name="description"
        label="留言"
        rules={[
          {
            message: "留言最多 150 字",
            validator: (_, value) => {
              if (value.length >= 150) {
                setErrorStatus(true);
                return Promise.reject();
              }
              setErrorStatus(false);
              return Promise.resolve();
            },
          },
        ]}
      >
        <TextArea
          placeholder="留一段話鼓勵創作者吧！"
          showCount
          maxLength={150}
          autoSize={{ minRows: 5 }}
          style={{ borderColor: errorStatus ? "#ff4d4f" : "#d9d9d9" }}
        />
      </Form.Item>
    </Form>
  );
}

function PlanItem({
  plan,
  handleCardClick,
}: {
  plan: SubscriptionPlan;
  handleCardClick: () => void;
}) {
  return (
    <Space
      direction="vertical"
      className="subscription-plan-card"
      onClick={handleCardClick}
    >
      <Typography.Text>{plan.title}</Typography.Text>
      <Typography.Text>
        NT {plan.monthPrice.toLocaleString()} /月
      </Typography.Text>
      <Typography.Text>
        NT {plan.yearPrice.toLocaleString()} /年
      </Typography.Text>
    </Space>
  );
}
