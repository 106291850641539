import { Result } from "antd";
import React from "react";

export interface Props {
  message: string;
}

export default function NotFound({ message }: Props) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        height: "100vh",
        alignItems: "center",
      }}
    >
      <Result status="404" title={message} />
    </div>
  );
}
