import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithCustomToken,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import Donate from "./Donate";
import DonateCallback from "./DonateCallback";
import DonateSuccess from "./DonateSuccess";
import NotFound from "./NotFound";
import config from "../config";
import { createContext, useEffect, useState } from "react";
import { getCookie, removeCookie } from "typescript-cookie";

const defaultAuth = { userLoginStatus: false };
export const AuthContext = createContext(defaultAuth);

function App() {
  const [userLogin, setUserLogin] = useState({ userLoginStatus: false });
  useEffect(() => {
    const configFirebase = JSON.parse(config.firebaseConfig);
    const app = initializeApp(configFirebase);
    const auth = getAuth(app);
    const token = getCookie("custom");
    if (token) {
      removeCookie("custom");
      const getUserData = async () => {
        if (token) {
          try {
            await signInWithCustomToken(auth, token);
            setUserLogin({ userLoginStatus: true });
          } catch (error) {
            console.error("Error:", error);
          }
        }
      };
      getUserData();
    } else {
      onAuthStateChanged(auth, (user) => {
        if (!user) {
          return;
        }
        setUserLogin({ userLoginStatus: true });
      });
    }
    window.addEventListener("beforeunload", () => {
      signOut(auth);
    });
  }, []);

  return (
    <div className="App">
      <AuthContext.Provider value={userLogin}>
        <Router>
          <Switch>
            <Route exact path="/podcasts/:podcastId">
              <Donate />
            </Route>
            <Route exact path="/podcasts/:podcastId/success">
              <DonateSuccess />
            </Route>
            <Route exact path="/podcasts/:podcastId/callback">
              <DonateCallback />
            </Route>
            <Route path="/">
              <NotFound message="抱歉，找不到這個頁面" />
            </Route>
          </Switch>
        </Router>
      </AuthContext.Provider>
    </div>
  );
}

export default App;
