import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import config from "../config";

export function getFirebaseAuth() {
  const configFirebase = JSON.parse(config.firebaseConfig);
  const app = initializeApp(configFirebase);
  const auth = getAuth(app);
  return auth;
}
