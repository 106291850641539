import { Typography } from "antd";
import { Podcast } from "../typings/podcast";

export interface PodcastInfoProps {
  podcast: Podcast;
  coverSize: number;
  titleSize: number;
  subTitleSize: number;
}

export default function PodcastInfo({
  podcast,
  coverSize,
  titleSize,
  subTitleSize,
}: PodcastInfoProps) {
  const { cover, title, artistName } = podcast;

  return (
    <>
      <img
        alt="podcast-cover"
        style={{ width: coverSize, height: coverSize }}
        src={cover}
      />
      <Typography.Text
        style={{
          fontWeight: 700,
          fontSize: titleSize,
          color: "#fff",
          marginBottom: 8,
          marginTop: 24,
        }}
      >
        {title}
      </Typography.Text>
      <Typography.Text
        style={{
          fontWeight: 700,
          fontSize: subTitleSize,
          color: "#fff",
          opacity: 0.6,
        }}
      >
        {artistName}
      </Typography.Text>
    </>
  );
}
