import { Button, Result } from "antd";
import React from "react";
import { useParams } from "react-router-dom";
import config from "../config";

export default function DonateSuccess() {
  const { podcastId } = useParams<{ podcastId: string }>();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        height: "100vh",
        alignItems: "center",
      }}
    >
      <Result
        status="success"
        title="感謝你的贊助，我將繼續創作更多精彩的內容！"
        extra={[
          <Button
            type="primary"
            size="large"
            onClick={() => {
              window.location.href = `${config.publicUrl}/podcasts/${podcastId}`;
            }}
          >
            回到贊助頁面
          </Button>,
          <Button
            size="large"
            onClick={() => {
              window.location.href = `${config.playerUrl}/p/${podcastId}`;
            }}
          >
            前往 Podcast 節目
          </Button>,
        ]}
      />
    </div>
  );
}
