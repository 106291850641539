export interface Config {
  publicUrl: string;
  playerUrl: string;
  apiEndpoint: string;
  apiToken: string;
  tapPayAppId: number;
  tapPayAppKey: string;
  tapPayEnv: string;
  googlePayMerchantId: string;
  applePayMerchantId: string;
  firebaseConfig: string;
}

const devConfig: Config = {
  publicUrl: "http://localhost:3000",
  playerUrl: "https://player-beta.soundon.fm",
  apiEndpoint: "https://api-beta.soundon.fm/v2",
  apiToken: "HigXVBCE0cdQawtqEB7u",
  tapPayAppId: 19808,
  tapPayAppKey:
    "app_aZCuXlotGyVyw2kcNH8mmu9IyS3ELeP1OCGCfyb887Cu85An3Fio96zYIGNu",
  tapPayEnv: "sandbox",
  googlePayMerchantId: "BCR2DN6T27V6XQBB",
  applePayMerchantId: "merchant.fm.soundon.pay",
  firebaseConfig:
    '{"apiKey":"AIzaSyA9CqSnhrZ8QG5tZoSUtbrza7T99XGQIoA","authDomain":"soundon-dev.firebaseapp.com","databaseURL":"https://soundon-dev.firebaseio.com","projectId":"soundon-dev","storageBucket":"soundon-dev.appspot.com","messagingSenderId":"1075813477490","appId":"1:1075813477490:web:9842457631b07a3fd0a6eb","measurementId":"G-9KX4L0X3J3"}',
};

const betaConfig: Config = {
  publicUrl: "https://pay-beta.soundon.fm",
  playerUrl: "https://player-beta.soundon.fm",
  apiEndpoint: "https://api-beta.soundon.fm/v2",
  apiToken: "HigXVBCE0cdQawtqEB7u",
  tapPayAppId: 19808,
  tapPayAppKey:
    "app_aZCuXlotGyVyw2kcNH8mmu9IyS3ELeP1OCGCfyb887Cu85An3Fio96zYIGNu",
  tapPayEnv: "sandbox",
  googlePayMerchantId: "BCR2DN6T27V6XQBB",
  applePayMerchantId: "merchant.fm.soundon.pay",
  firebaseConfig:
    '{"apiKey":"AIzaSyA9CqSnhrZ8QG5tZoSUtbrza7T99XGQIoA","authDomain":"soundon-dev.firebaseapp.com","databaseURL":"https://soundon-dev.firebaseio.com","projectId":"soundon-dev","storageBucket":"soundon-dev.appspot.com","messagingSenderId":"1075813477490","appId":"1:1075813477490:web:9842457631b07a3fd0a6eb","measurementId":"G-9KX4L0X3J3"}',
};

const prodConfig: Config = {
  publicUrl: "https://pay.soundon.fm",
  playerUrl: "https://player.soundon.fm",
  apiEndpoint: "https://api.soundon.fm/v2",
  apiToken: "KilpEMLQeNzxmNBL55u5",
  tapPayAppId: 19808,
  tapPayAppKey:
    "app_aZCuXlotGyVyw2kcNH8mmu9IyS3ELeP1OCGCfyb887Cu85An3Fio96zYIGNu",
  tapPayEnv: "production",
  googlePayMerchantId: "BCR2DN6T27V6XQBB",
  applePayMerchantId: "merchant.fm.soundon.pay",
  firebaseConfig:
    '{"apiKey":"AIzaSyB1gTYyA_fN-YgXNxFn2JGhYjClpOH_Ew4","authDomain":"soundon.firebaseapp.com","databaseURL":"https://soundon.firebaseio.com","projectId":"gold-bruin-237907","storageBucket":"soundon.appspot.com","messagingSenderId":"1075813477490","appId":"1:1075813477490:web:9842457631b07a3fd0a6eb","measurementId":"G-9KX4L0X3J3"}',
};

const configMap: { [key: string]: Config } = {
  dev: devConfig,
  beta: betaConfig,
  prod: prodConfig,
};

function getConfig(env?: string): Config {
  return (env && configMap[env]) || devConfig;
}

export default getConfig(process.env.REACT_APP_ENV);
