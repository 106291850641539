import { Flex, Radio, Typography } from "antd";
import { CSSProperties, useEffect, useState } from "react";
import {
  DonationSetting,
  SubscriptionPlan,
  SubscriptionProduct,
} from "../typings/donate";
import DonateSingleForm from "./DonateSingleForm";
import SubScriptionDonateForm from "./SubScriptionDonateForm";
import SubscriptionDonateFAQ from "./SubscriptionDonateFAQ";
import { getProducts } from "../api";

enum MenuOptions {
  SUBSCRIPTION = "subscription",
  SINGLE = "single",
}

export default function DonateForm({
  podcastId,
  setting,
  isMobile,
}: {
  podcastId: string;
  setting: DonationSetting;
  isMobile: boolean;
}) {
  const [menuSelect, setMenuSelect] = useState<MenuOptions>(
    podcastId === "f56a52a8-4a04-4936-affe-3eb8c5ac96db" || isMobile
      ? MenuOptions.SINGLE
      : MenuOptions.SUBSCRIPTION
  );

  const [product, setProduct] = useState<SubscriptionPlan[]>();

  useEffect(() => {
    const loadData = async () => {
      try {
        const product = await getProducts(podcastId);
        setProduct(subscriptionPlan(product));
      } catch (err) {
      } finally {
      }
    };

    loadData();
  }, [podcastId]);

  const items =
    podcastId === "f56a52a8-4a04-4936-affe-3eb8c5ac96db" || isMobile
      ? [
          {
            label: "單次贊助",
            key: MenuOptions.SINGLE,
          },
        ]
      : [
          {
            label: "訂閱贊助",
            key: MenuOptions.SUBSCRIPTION,
          },
          {
            label: "單次贊助",
            key: MenuOptions.SINGLE,
          },
        ];

  return (
    <Flex vertical style={{ padding: "0 16px" }}>
      <Typography.Title level={3} style={{ fontWeight: 400, marginBottom: 32 }}>
        {setting?.pageTitle || "你的支持是我創作的動力！"}
      </Typography.Title>
      <Radio.Group
        buttonStyle="solid"
        value={menuSelect}
        onChange={(e) => setMenuSelect(e.target.value as MenuOptions)}
        style={{ marginBottom: 24 }}
      >
        {items.map((item) => (
          <Radio.Button
            key={item.key}
            value={item.key}
            style={{
              width: "50%",
              height: "fit-content",
              borderRadius: 0,
              textAlign: "center",
              padding: "6px 12px",
              boxSizing: "border-box",
            }}
          >
            {item.label}
          </Radio.Button>
        ))}
      </Radio.Group>
      {menuSelect === MenuOptions.SUBSCRIPTION &&
        (product ? (
          <>
            <SubScriptionDonateForm subscriptionPlans={product} />
            <SubscriptionDonateFAQ />
          </>
        ) : (
          <>此節目未開放訂閱方案</>
        ))}
      {menuSelect === MenuOptions.SINGLE &&
        (!setting || !setting.isEnabled ? (
          <>此節目未開放贊助</>
        ) : (
          <DonateSingleForm podcastId={podcastId} setting={setting} />
        ))}
    </Flex>
  );
}

function subscriptionPlan(products: SubscriptionProduct[]): SubscriptionPlan[] {
  return products.map((product) => {
    const {
      id,
      data: { title, description, podcastProductPrices, appreciation },
    } = product;

    const planPrice = podcastProductPrices?.reduce(
      (acc, cur) => {
        if (cur.timeUnit === "month") {
          return { ...acc, monthPrice: cur.price };
        } else if (cur.timeUnit === "year") {
          return { ...acc, yearPrice: cur.price };
        } else {
          return acc;
        }
      },
      { monthPrice: 0, yearPrice: 0 }
    );

    return {
      ...planPrice,
      title,
      description,
      appreciation,
      planId: id,
    };
  });
}
