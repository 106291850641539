import { Alert, Button, Form, Input, Modal, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import { AsYouType, isValidPhoneNumber } from "libphonenumber-js";
import { useCallback, useState } from "react";
import { createDonation } from "../api";
import config from "../config";
import { PaymentProps } from "../typings/payment";
import tapPayLogo from "./assets/tap-pay.svg";

export default function ApplePayModal({
  isOpen,
  close,
  podcastId,
  donorName,
  donorMessage,
  amount,
}: PaymentProps) {
  const [form] = useForm();
  const [isPersonalInfoComplete, setIsPersonalInfoComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [formatter] = useState(new AsYouType());
  const [isValidPhone, setIsValidPhone] = useState(true);

  const handleFinish = useCallback(
    (values: { [key: string]: string }) => {
      setLoading(true);
      const { name, phoneNumber, email } = values;
      TPDirect.paymentRequestApi.getPrime((result: any) => {
        if (result.status !== 0) {
          setErrorMessage(`${result.msg} (${result.status})`);
          setLoading(false);
          return;
        }

        createDonation(podcastId, {
          prime: result.prime,
          donorName,
          donorMessage,
          amount,
          payerInfo: {
            name,
            email,
            phoneNumber,
          },
        })
          .then(() => {
            window.location.href = `${config.publicUrl}/podcasts/${podcastId}/success`;
          })
          .catch((err: any) => {
            setErrorMessage(JSON.stringify(err));
          })
          .finally(() => {
            setLoading(false);
          });
      });
    },
    [amount, donorMessage, donorName, podcastId]
  );

  return (
    <Modal
      centered
      title="輸入個人資訊"
      open={isOpen}
      onOk={close}
      onCancel={close}
      footer={null}
      forceRender
    >
      <Form
        form={form}
        onValuesChange={async (changedValues, allValues) => {
          const { name, phoneNumber, email } = allValues;
          const isValid = !!phoneNumber && isValidPhoneNumber(phoneNumber);
          setIsPersonalInfoComplete(
            !!name && !!email && !!phoneNumber && isValid
          );

          if (changedValues.phoneNumber) {
            formatter.reset();
            const formattedPhoneNumber = formatter.input(phoneNumber);
            form.setFieldsValue({ phoneNumber: formattedPhoneNumber });
            setIsValidPhone(isValid);
          }
        }}
        initialValues={{ phoneNumber: "+886 09" }}
        onFinish={handleFinish}
        layout="vertical"
      >
        <Form.Item name="name" label="姓名">
          <Input size="large" />
        </Form.Item>
        <Form.Item
          name="phoneNumber"
          label="電話"
          help={isValidPhone ? undefined : "請輸入正確的電話號碼"}
          validateStatus={isValidPhone ? undefined : "error"}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          name="email"
          label="信箱"
          rules={[{ type: "email", message: "請輸入正確的 Email" }]}
        >
          <Input size="large" />
        </Form.Item>
        <div style={{ display: "flex" }}>
          <Button
            style={{ flex: 1, marginRight: 28 }}
            type="primary"
            size="large"
            onClick={form.submit}
            loading={loading}
            disabled={!isPersonalInfoComplete}
          >
            確認
          </Button>
          <div style={{ flex: 1, marginLeft: 28 }} />
        </div>
        {errorMessage && (
          <Alert type="error" message="付款失敗" description={errorMessage} />
        )}
        <div style={{ display: "flex", marginTop: 32 }}>
          <img src={tapPayLogo} alt="tap-pay-logo" />
          <Typography.Text
            style={{
              fontSize: 12,
              color: "rgba(0, 0, 0, 0.45)",
              marginLeft: 16,
            }}
          >
            本公司採用喬睿科技 TapPay 金流交易系統,消費者刷卡時直接在銀行端
            系統中交易,本公司不會留下您的信用卡資料,以保障你的權益,資料
            傳輸過程採用嚴密的 SSL 2048bit 加密技術保護。
          </Typography.Text>
        </div>
      </Form>
    </Modal>
  );
}
